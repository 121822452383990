import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Playlist } from "../../types";
import { getJWPlaylistVideos } from "../../utils";
import VideoPlaylist from "../VideoPlaylist";
import { Props } from "./types";
import { getProgramPageVideoPlaylistSettings } from "../../redux/selectors";

const RaceVideoPlaylist = ({
  qaLabel = "",
  currentRaceId,
  currentRaceDate
}: Props) => {
  const { playlistUrl, playerScript, licenseKey } = useSelector(
    getProgramPageVideoPlaylistSettings
  );

  const [title, setTitle] = useState<string>("");
  const [playlist, setPlaylist] = useState<Playlist[]>([]);
  const [raceId, setRaceId] = useState<string>("");
  const [raceDate, setRaceDate] = useState<string>("");

  const showSection = currentRaceId === raceId && currentRaceDate === raceDate;

  useEffect(() => {
    getJWPlaylistVideos(playlistUrl, playerScript, licenseKey).then((data) => {
      setPlaylist(data.playlist);
      setTitle(data.title);
      setRaceId(data.raceId);
      setRaceDate(data.raceDate);
    });
  }, [playlistUrl]);

  return showSection ? (
    <VideoPlaylist title={title} qaLabel={qaLabel} playlist={playlist} />
  ) : (
    <></>
  );
};

export default RaceVideoPlaylist;
